<template>
  <StoryBanner
    :srcset="srcset"
    :image-source="imageSource"
    :image-alt="imageAlt"
    :text-position="textPosition"
    :color="color"
  >
    {{ text }}
  </StoryBanner>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      imgIngredient() {
        return this.getIngredient("image")
      },
      imageSource() {
        return this.imgIngredient?.value
      },
      imageAlt() {
        return this.imgIngredient?.altText
      },
      srcset() {
        return this.imgIngredient?.srcset
      },
      text() {
        return this.getValue("text")
      },
      textPosition() {
        return this.getValue("text_position")
      },
      color() {
        return this.getValue("color")
      },
    },
  }
</script>
