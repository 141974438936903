<template>
  <Container space-before space-after>
    <div
      :class="[
        'story-banner',
        `story-banner--text-${textPosition}`,
        `story-banner--color-${color}`,
      ]"
    >
      <div class="text">
        <slot />
      </div>
      <picture class="story-banner-image">
        <source
          v-for="imgTypes in createFormatArray(srcset)"
          :key="imgTypes.type"
          :type="imgTypes.type"
          :srcset="imgTypes.srcsetstring"
          sizes="(min-width: 1344px) 704px, (min-width: 660px) calc(55vw - 64px)"
        />

        <img
          role="img"
          :aria-label="imageAlt"
          :src="imageSource"
          loading="lazy"
        />
      </picture>
    </div>
  </Container>
</template>

<script>
  import createFormatArray from "~/mixins/createFormatArray"

  import Container from "~/components/Container"
  export default {
    components: { Container },
    mixins: [createFormatArray],

    props: {
      imageSource: {
        type: String,
        default: "",
      },
      srcset: {
        type: Array,
        default: () => [],
      },
      imageAlt: {
        type: String,
        required: false,
        default: null,
      },
      textPosition: {
        type: String,
        default: "left",
        validator(value) {
          return ["left", "right"].includes(value)
        },
      },
      color: {
        type: String,
        required: false,
        default: "red",
        validator(value) {
          return ["red", "yellow", "blue", "purple"].includes(value)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "assets/styles";
  $story-height: $base-spacing * 40;
  $offset: $space-l;
  $overlap: 120px;

  .story-banner {
    display: flex;
    align-items: stretch;
    min-height: $story-height;
    padding-bottom: $offset;
    @include viewport("mini") {
      padding-bottom: 0;
      flex-direction: column;
      &.story-banner--text-right {
        align-items: flex-end;
      }
    }
  }

  .text {
    position: relative;
    color: white;
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: flex-start;

    @apply blockquote;
    box-shadow: $shadow;
    border-radius: $border-radius;

    padding: $gutter-double;

    .story-banner--text-right & {
      border-left: $overlap solid transparent;
      margin-left: -$overlap;
      @include viewport("mini") {
        margin-left: 0;
        border-left: 0;
      }
    }

    .story-banner--text-left & {
      border-right: $overlap solid transparent;
      margin-right: -$overlap;
      @include viewport("mini") {
        margin-right: 0;
        border-right: 0;
      }
    }

    .story-banner--color-red & {
      background-color: $red-shape;
    }

    .story-banner--color-blue & {
      background-color: $blue-shape;
    }

    .story-banner--color-yellow & {
      color: $onyx;
      background-color: $yellow-300;
    }

    .story-banner--color-green & {
      background-color: $green-shape;
    }

    .story-banner--color-purple & {
      background-color: $purple-shape;
    }

    @include viewport("mini") {
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      padding: $base-spacing * 4;
    }
    @include viewport("sm") {
      padding: $base-spacing * 8;
    }
  }

  .story-banner-image {
    flex-shrink: 0;
    transform: translateY($offset);
    width: 55%;
    height: auto;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius;
    }
    @media (min-width: $max-outbreak-width) {
      max-width: calc($max-outbreak-width / 2);
    }

    .story-banner--text-right & {
      order: -1;
    }

    @include viewport("mini") {
      transform: initial;
      order: inherit;
      width: 100%;
    }
  }
</style>
